.jss_media > .jss_media_item > svg {
    position: absolute;
    z-index: 1;
    top: 0;
}

.jss_media > .jss_media_item > .shape-text {
    position: absolute;
    z-index: 2;
    padding: 5px;
    box-sizing: border-box;
    outline: none;
    white-space: pre;
    text-wrap: wrap;
}

.jss-shape {
    overflow: visible;
}

.shape-types {
    display: grid;
    grid-template-columns: repeat(10, 1fr);
    justify-items: center;
    gap: 15px;
}

.shape-types > button {
    width: 18px;
    height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #ffff;
    border: 0;
    padding: 0;
    cursor: pointer;
}

.shape-types > button > svg {
    display: block;
}

.shape-category {
    margin-bottom: 25px;
}

.shape-category label {
    margin-bottom: 15px;
    display: block;
    font-weight: bold;
}